import React, { useState, useEffect } from "react";
import { a38 } from "../Api/RootApiPath";

export const DateTime = ({ showInv, gstType }) => {
  var [date, setDate] = useState(new Date());
  const [allOrders, setAllOrders] = useState([]);
  const [lastOrderNo, setLastOrderNo] = useState("");
  const [newOrderNo, setNewOrderNo] = useState("");
  const [loading, setLoading] = useState(true);
  let nextNo = "";
  let nextNo1 = [];
  let nextNo2 = [];
  let newNo = [];

  useEffect(() => {
    fetchOrders();
  }, []);
  useEffect(() => {
    fetchOrders();
  }, [gstType]);
  // useEffect(() => {
  //   // Fetch data when component mounts
  //   fetchOrders();
  //   // Fetch data every 10 seconds after the component mounts
  //   const interval = setInterval(fetchOrders, 10000);

  //   // Cleanup the interval when the component is unmounted
  //   return function cleanup() {
  //     clearInterval(interval);
  //   };
  // }, []); // Empty dependency array ensures this effect runs once after initial render
  // useEffect(() => {
  //   var timer = setInterval(() => setDate(new Date()), 1000);
  //   return function cleanup() {
  //     clearInterval(timer);
  //   };
  // });
  const fetchOrders = async () => {
    setLastOrderNo("");
    setAllOrders([]);
    await fetch(a38)
      .then((res) => res.json())
      .then((response) => {
        let rcvdData = response.data.reverse();
        let gstTypeOrder = rcvdData.filter((x) =>
          gstType === true ? x.billType === "true" : x.billType === "false"
        );
        setAllOrders(gstTypeOrder);
        // changeLastNo(
        //   rcvdData.length > 0 ? rcvdData[0].invoiceNo : "1st Order/"
        // );

        changeLastNo(
          gstTypeOrder.length > 0 ? gstTypeOrder[0].invoiceNo : "1st Order/"
        );
        console.log(rcvdData);
      });
  };
  const changeLastNo = (no) => {
    setLastOrderNo(no);
    setLoading(!loading);
  };

  useEffect(() => {
    nextNo = lastOrderNo.split("/");
    nextNo1 = nextNo[0].replace(/\d+/g, "");
    nextNo2 = parseInt(nextNo[0].replace(/\D+/g, "")) + 1;
    nextNo[0] = `${nextNo1}${nextNo2}`;
    newNo = nextNo.join("/");
    setNewOrderNo(newNo);
  }, [loading]);
  // console.log(gstType, "gstType Changed");
  // console.log(gstType, "gstType Changed");
  // console.log(gstType, "gstType Changed");
  // console.log(gstType, "gstType Changed");
  return (
    <div
      className="adminInvoiceAddTitles"
      style={{
        // marginInline: "5px",
        marginTop: "20px",
        width: "98%",
        flexWrap: "wrap",
        display: "flex",
        // fontSize: "14px",
        marginRight: "20px",
        justifyContent: "space-between",
        backgroundColor: "rgba(2, 168, 181, 0.2)",
        // padding: "10px",
        fontWeight: "bold",
      }}
    >
      <p style={{ margin: "0px", marginRight: "15px" }}>
        Date : {date.toLocaleDateString()}
      </p>
      {showInv ? <p style={{ margin: "0px" }}>{newOrderNo}</p> : null}
      <p style={{ margin: "0px" }}> Time : {date.toLocaleTimeString()}</p>
    </div>
  );
};

export default DateTime;
