import React, { useEffect, useState } from "react";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import AdminHeading from "../Heading/AdminHeading";
import "../../PagesStyles/AdminReport.css";
import { a38, a73, a87 } from "../../../Api/RootApiPath";

export default function AdminCashReport() {
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);
  const [allData, setAllData] = useState([]);
  const [allOrdersData, setAllOrdersData] = useState([]); // Added this line
  const [received, setReceived] = useState([
    { name: "Gold Sale", weight: 0, amount: 0 },
    { name: "Silver Sale", weight: 0, amount: 0 },
    { name: "Platinum Sale", weight: 0, amount: 0 },
    { name: "Diamond Sale", weight: 0, amount: 0 },
    { name: "Stone Sale", weight: 0, amount: 0 },
    { name: "Order Advance", weight: 0, amount: 0 },
    { name: "Old Balance", weight: 0, amount: 0 },
    { name: "Repair", weight: 0, amount: 0 },
    { name: "Total", weight: 0, amount: 0 },
  ]);
  const [paid, setPaid] = useState([
    { name: "Gold Purchase", weight: 0, amount: 0 },
    { name: "URD Purchase", weight: 0, amount: 0 },
    { name: "Silver Purchase", weight: 0, amount: 0 },
    { name: "Platinum Purchase", weight: 0, amount: 0 },
    { name: "Diamond Purchase", weight: 0, amount: 0 },
    { name: "Stone Purchase", weight: 0, amount: 0 },
    { name: "Advance Paid", weight: 0, amount: 0 },
    { name: "Expenses", weight: 0, amount: 0 },
    { name: "Total", weight: 0, amount: 0 },
  ]);
  const [paymentMode, setPaymentMode] = useState([
    { name: "Cash", amount: 0 },
    { name: "UPI", amount: 0 },
    { name: "RTGS", amount: 0 },
    { name: "Cheque", amount: 0 },
    { name: "Card", amount: 0 },
    { name: "Advance Used", amount: 0 },
    { name: "Balance", amount: 0 },
    { name: "Total", amount: 0 },
  ]);
  const [paymentMode2, setPaymentMode2] = useState([
    { name: "Cash", amount: 0 },
    { name: "UPI", amount: 0 },
    { name: "RTGS", amount: 0 },
    { name: "Cheque", amount: 0 },
    { name: "Card", amount: 0 },
    { name: "Advance Used", amount: 0 },
    { name: "Balance", amount: 0 },
    { name: "Total", amount: 0 },
  ]);
  const [metalPayment, setMetalPayment] = useState([
    { name: "Fine Gold", amount: 0 },
    { name: "Fine Silver", amount: 0 },
  ]);
  const [metalPayment2, setMetalPayment2] = useState([
    { name: "Fine Gold", amount: 0 },
    { name: "Fine Silver", amount: 0 },
  ]);
  const [approxProfit, setApproxProfit] = useState([
    { name: "Gold", amount: 0 },
    { name: "Silver", amount: 0 },
    { name: "Amount", amount: 0 },
  ]);
  const [cash, setCash] = useState([
    { name: "Inward Cash", amount: 0 },
    { name: "Outward Cash", amount: 0 },
    { name: "Cash In Hand", amount: 0 },
  ]);
  const [loadingAllOrders, setLoadingAllOrders] = useState(true);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    console.log("New Date Selected: ", newDate);
    setDate(newDate);
  };

  useEffect(() => {
    console.log("Fetching Data for Date: ", date);
    const fetchData = async () => {
      try {
        const response = await fetch(a73);
        const data = await response.json();
        console.log("Fetched Data: ", data);
        setAllData(data.data);

        const today = new Date();

        const todayPayments = data.data.filter((payment) => {
          const paymentDate = new Date(payment.createdOn);

          const isSaleBillPayment =
            payment.paymentSource &&
            payment.paymentSource.toLowerCase().includes("sale");

          return (
            isSaleBillPayment &&
            paymentDate.getDate() === today.getDate() &&
            paymentDate.getMonth() === today.getMonth() &&
            paymentDate.getFullYear() === today.getFullYear()
          );
        });

        const oldBalance = todayPayments.reduce(
          (total, payment) => total + parseFloat(payment.amount) || 0,
          0
        );

        setReceived((prevReceived) => {
          const updatedReceived = [...prevReceived];

          // Update Old Balance at index 6
          updatedReceived[6] = {
            ...updatedReceived[6],
            amount: oldBalance,
          };

          // Update Total at index 8
          const totalWeight = updatedReceived
            .slice(0, 8)
            .reduce((sum, item) => sum + (parseFloat(item.weight) || 0), 0);
          const totalAmount = updatedReceived
            .slice(0, 8)
            .reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0);
          updatedReceived[8] = {
            ...updatedReceived[8],
            weight: totalWeight,
            amount: totalAmount + oldBalance,
          };

          return updatedReceived;
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [date]);

  useEffect(() => {
    console.log("Fetching Orders Data");
    const fetchAllOrdersData = async () => {
      try {
        const response = await fetch(a87);
        const data = await response.json();
        console.log("Fetched Orders Data: ", data);
        setAllOrdersData(data.data);
        setLoadingAllOrders(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAllOrdersData();
  }, []);

  const updateReceivedData = async () => {
    if (!loadingAllOrders) {
      try {
        const response = await fetch(a87);
        const data = await response.json();
        const ordersData = data.data;

        setReceived((prevReceived) => {
          const updatedReceived = [...prevReceived];

          // Update data based on ordersData
          const categories = ["gold", "silver", "platinum", "diamond", "stone"];

          categories.forEach((category, index) => {
            updatedReceived[index] = {
              ...updatedReceived[index],
              weight: ordersData.reduce(
                (total, order) =>
                  total +
                  (order.categoryName.toLowerCase().includes(category)
                    ? parseFloat(order.grosswt) || 0
                    : 0),
                0
              ),
              amount: ordersData.reduce(
                (total, order) =>
                  total +
                  (order.categoryName.toLowerCase().includes(category)
                    ? parseFloat(order.mrp !== "0" ? order.mrp : order.price) ||
                      0
                    : 0),
                0
              ),
            };
          });

          // Update Total at index 8
          const totalWeight = updatedReceived
            .slice(0, 8)
            .reduce((sum, item) => sum + (parseFloat(item.weight) || 0), 0);
          const totalAmount = updatedReceived
            .slice(0, 8)
            .reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0);
          updatedReceived[8] = {
            ...updatedReceived[8],
            weight: totalWeight,
            amount: totalAmount,
          };

          return updatedReceived;
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    updateReceivedData();
  }, [loadingAllOrders]);

  return (
    <div>
      <AdminHeading />
      <div style={{ paddingTop: "130px" }}>
        <AdminBreadCrump
          title={"Reports"}
          companyName={"Loyalstring"}
          module={"Reports"}
          page={"Cash Report"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="cashReportUpperBox">
              <div>
                <label>Opening Cash</label>
                <input type="text" value={0} readOnly />
              </div>
              <div>
                <label>Cash In Hand</label>
                <input type="text" value={0} readOnly />
              </div>
              <div>
                <label>Date</label>
                <input
                  type="date"
                  id="dateInput"
                  name="dateInput"
                  value={date}
                  onChange={handleDateChange}
                />
              </div>
            </div>

            <div className="cashReportLowerBox">
              <div className="cashReportLowerItemBox">
                <div>
                  <h3>Received</h3>
                  <table>
                    <thead></thead>
                    <tbody>
                      {received.map((x, index) => {
                        return (
                          <tr key={index}>
                            <td>{x.name} </td>
                            <td>{parseFloat(x.weight).toFixed(3)}</td>
                            <td>{parseFloat(x.amount).toFixed(0)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cashReportLowerItemBox">
                <div>
                  <h3>Paid</h3>
                  <table>
                    <thead></thead>
                    <tbody>
                      {paid.map((x, index) => {
                        return (
                          <tr key={index}>
                            <td>{x.name} </td>
                            <td>{parseFloat(x.weight).toFixed(3)}</td>
                            <td>{parseFloat(x.amount).toFixed(0)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cashReportLowerItemBox">
                <div>
                  <h3>Payment Mode (Received)</h3>
                  <table>
                    <thead></thead>
                    <tbody>
                      {paymentMode.map((x, index) => {
                        return (
                          <tr key={index}>
                            <td>{x.name} </td>

                            <td>{parseFloat(x.amount).toFixed(0)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cashReportLowerItemBox">
                <div>
                  <h3>Payment Mode (Paid)</h3>
                  <table>
                    <thead></thead>
                    <tbody>
                      {paymentMode2.map((x, index) => {
                        return (
                          <tr key={index}>
                            <td>{x.name} </td>
                            <td>{parseFloat(x.amount).toFixed(0)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cashReportLowerItemBox">
                <div>
                  <h3>Metal Payment (Received)</h3>
                  <table>
                    <thead></thead>
                    <tbody>
                      {metalPayment.map((x, index) => {
                        return (
                          <tr key={index}>
                            <td>{x.name} </td>
                            <td>{parseFloat(x.amount).toFixed(0)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cashReportLowerItemBox">
                <div>
                  <h3>Metal Payment (Paid)</h3>
                  <table>
                    <thead></thead>
                    <tbody>
                      {metalPayment2.map((x, index) => {
                        return (
                          <tr key={index}>
                            <td>{x.name} </td>
                            <td>{parseFloat(x.amount).toFixed(0)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cashReportLowerItemBox">
                <div>
                  <h3>Approx Profit</h3>
                  <table>
                    <thead></thead>
                    <tbody>
                      {approxProfit.map((x, index) => {
                        return (
                          <tr key={index}>
                            <td>{x.name} </td>
                            <td>{parseFloat(x.amount).toFixed(0)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cashReportLowerItemBox">
                <div>
                  <h3>CASH</h3>
                  <table>
                    <thead></thead>
                    <tbody>
                      {cash.map((x, index) => {
                        return (
                          <tr key={index}>
                            <td>{x.name} </td>
                            <td>{parseFloat(x.amount).toFixed(0)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
